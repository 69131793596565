import { Box, Button, Flex } from "@chakra-ui/react";
import IconBox from "components/icons/IconBox";
import { IconPaypal } from "components/icons/Icons";
import { axiosService } from "network/axios";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { updateProjectStatus } from "store/customerProject/reducer";
import { uploadProjectSelector } from "store/customerProject/selector";
import { setAlertMessageRequest } from "store/ui/reducer";
import { userSelector } from "store/user/selector";

interface PaymentResponse {
  payment_status: string;
  results: object;
}

export default function PaypalModal(props: {
  totalPrice: number;
  completeOrder: Function;
}) {
  const user = useSelector(userSelector);
  const { totalPrice, completeOrder } = props;
  const { orderId } = useParams();
  const uploadProject = useSelector(uploadProjectSelector);
  const paypalRef = useRef();
  const paypalClientId =
    "AStc1x3M_Pvk6SlxH5mMpQ6ZX6HqJAD7v-WKo6OtTDh3cLyxEbtVG0ZdQJPhPNzDP6Jj7eUCx_eBSQUH";
  const dispatch = useDispatch();
  // const connectPaypal = () => {
  //   dispatch(
  //     updateProjectStatus({
  //       paymentOption: "paypal",
  //     })
  //   );
  // };

  useEffect(() => {
    window &&
      (window as any).paypal
        .Buttons({
          createOrder: function (data: any, actions: any) {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: totalPrice,
                  },
                },
              ],
            }).then(async (orderID: any) => {
              return orderID;
            });
          },
          onApprove: function (data: any, actions: any) {
            return actions.order.capture().then(async function (orderData: any) {
                const payment_order_id = orderData.id;
                //const paymentMethodId = orderData.purchase_units[0].payments.captures[0].id;
        
                try {
                    const res = await axiosService.post<PaymentResponse>(
                        `/shipping/process-paypal-payment/${orderId}`,
                        {
                          payment_order_id: payment_order_id,
                        }
                    );

                    if (res?.data?.payment_status === "COMPLETED") {
                        completeOrder(2);
                    } else {
                        const alertMessage =
                            "Payment is not completed. Please contact the support team to confirm the payment status.";
                        dispatch(
                            setAlertMessageRequest({
                                type: "warning",
                                message: alertMessage,
                            })
                        );
                        completeOrder(1);
                    }
                } catch (error) {
                    // Handle Axios error
                    console.error("Error processing payment:", error);
                    const alertMessage =
                        "There was an error processing your payment. Please try again later.";
                    dispatch(
                        setAlertMessageRequest({
                            type: "error",
                            message: alertMessage,
                        })
                    );
                    completeOrder(10);
                }
            });
          }
        })
        .render(paypalRef.current);
  }, []);

  return <Box ref={paypalRef}></Box>;
}
